import '@fontsource/open-sans'
import './Header.css'
import '../../../assets/font/Font.css'
import logo from '../../../assets/images/header-logo.png'
import 'bootstrap/dist/css/bootstrap.min.css';
import {FaEarthAmericas, FaFacebookF, FaInstagram, FaLinkedin, FaXTwitter, FaYoutube} from "react-icons/fa6";
import {Link, useNavigate} from "react-router-dom";
import {Box, Drawer, useMediaQuery, useTheme} from "@mui/material";
import {Accordion} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {FaAngleDown} from "react-icons/fa";
import {LuMenu} from "react-icons/lu";
import {CgProfile} from "react-icons/cg";
import getButtonDetails from '../../Apis/buttonsDetails'
import eventData from '../../../data/event-categories.json';
import getMenu from "../../Apis/menuAPi";

const Header = ({isScrolled}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const [open, setOpen] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
    const [buttonDetails, setButtonDetails] = useState({});
    const [menuData, setMenu] = useState({});

    const fetchButtons = async () => {
        try {
            const response = await getButtonDetails('Home');
            setButtonDetails(response?.Details[0])
        } catch (e) {
            console.error('Error: ', e);
        }
    }
    const fetchMenu = async () => {
        try {
            const response = await getMenu();
            if (response?.status === 1) {
                setMenu(response?.data)
            }
        } catch (e) {
            console.error('Error: ', e);
        }
    }
    useEffect(() => {
        fetchButtons()
        fetchMenu()
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 768);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };
    const navigate = useNavigate();
    const loadGoogleTranslate = () => {
        if (!document.querySelector('#google-translate-script')) {
            const script = document.createElement('script');
            script.id = 'google-translate-script';
            script.type = 'text/javascript';
            script.src = `//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit`;
            script.onerror = () => {
                console.error('Failed to load Google Translate script');
            };
            document.head.appendChild(script);
        }
        window.googleTranslateElementInit = googleTranslateElementInit;
    };
    const googleTranslateElementInit = () => {
        if (window.google && window.google.translate) {
            document.getElementById('google_translate_element').innerHTML = '';
            if (isSmallScreen) {
                new window.google.translate.TranslateElement({
                    pageLanguage: 'fr', layout: window.google.translate.TranslateElement.InlineLayout.HORIZONTAL
                }, 'google_translate_element');
            } else {
                new window.google.translate.TranslateElement({
                    pageLanguage: 'en', layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
                }, 'google_translate_element');
            }
        } else {
            console.error('Google Translate API is not available.');
        }
    };
    useEffect(() => {
        loadGoogleTranslate();
    }, []);

    const DrawerList = (
        <Box sx={{width: '350px', height: '100%'}} role="presentation" className='mobile-menu'>
            {
                Object.keys(menuData).map((item, index) => (
                    menuData[item]?.items?.length > 0 ? (
                            <Accordion className='d-flex flex-column'>
                                <Accordion.Item eventKey={index}>
                                    <Accordion.Header>{menuData[item]?.menuTitle}</Accordion.Header>
                                    <Accordion.Body className='d-flex flex-column'>
                                        {
                                            menuData[item]?.items.map((item2, index2) => (
                                                item2?.items?.length && !item2?.clickable > 0 ? (
                                                        <Accordion className='d-flex flex-column'>
                                                            <Accordion.Item eventKey={index + index2}>
                                                                <Accordion.Header>{item2?.title}</Accordion.Header>
                                                                <Accordion.Body className='d-flex flex-column'>
                                                                    {
                                                                        item2?.items.map((item3, index3) => (
                                                                            <Link to={item3?.clickable && item3?.link}
                                                                                  className='mobile-item'
                                                                                  key={index3}
                                                                                  target={item3?.inNewTab ? "_blank" : "_parent"}
                                                                                  onClick={toggleDrawer(false)}>{item3?.title}</Link>
                                                                        ))
                                                                    }
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        </Accordion>
                                                    ) :
                                                    (<Link to={item2?.clickable && item2?.link} className='mobile-item'
                                                           key={index2}
                                                           target={item2?.inNewTab ? "_blank" : "_parent"}
                                                           onClick={toggleDrawer(false)}>{item2?.title}</Link>)
                                            ))
                                        }
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        ) :
                        (<Link to={menuData[item]?.clickable && menuData[item]?.link} className='mobile-item'
                               key={index}
                               target={menuData[item]?.inNewTab ? "_blank" : "_parent"}
                               onClick={toggleDrawer(false)}>{menuData[item].menuTitle}</Link>)
                ))
            }
        </Box>

        /*
        <Box sx={{width: '350px', height: '100%'}} role="presentation" className='mobile-menu'>
            <Link to="/" className='mobile-item' onClick={toggleDrawer(false)}>Home</Link>
            <Accordion className='d-flex flex-column'>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>About Us</Accordion.Header>
                    <Accordion.Body className='d-flex flex-column'>
                        <Link to="/founders-message" className='mobile-item' onClick={toggleDrawer(false)}>Founder's
                            Message</Link>
                        <Link to="/history" className='mobile-item' onClick={toggleDrawer(false)}>History</Link>
                        <Link to="/modern-pythian-games" className='mobile-item' onClick={toggleDrawer(false)}>Modern
                            Pythian Games</Link>
                        <Link to="/about-us" className='mobile-item' onClick={toggleDrawer(false)}>About Us</Link>
                        <Link to="/vision-and-mission" className='mobile-item' onClick={toggleDrawer(false)}>Vision and
                            Mission</Link>
                        <Link to="/aim-and-objectives" className='mobile-item' onClick={toggleDrawer(false)}>Aim and
                            Objectives</Link>
                    </Accordion.Body>
                </Accordion.Item>
                <Link to="/our-council" className='mobile-item' onClick={toggleDrawer(false)}>Our Council</Link>
                <Link to="/event" className='mobile-item' onClick={toggleDrawer(false)}>Events</Link>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Event Categories</Accordion.Header>
                    <Accordion.Body className='d-flex flex-column' onClick={toggleDrawer(false)}>
                        {
                            Object.keys(eventData).map((key) => (
                                <a onClick={() => navigate(`/event-categories/${key}`)}
                                   className='mobile-item'>{eventData[key].title}</a>
                            ))
                        }
                        <a href={buttonDetails?.signupbuttonurl} onClick={() => toggleDrawer(false)}
                           className='mobile-item'>Create your own
                            event</a>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Media</Accordion.Header>
                    <Accordion.Body className='d-flex flex-column'>
                        <Link to="/news" className='mobile-item' onClick={toggleDrawer(false)}>News</Link>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Link to="/membership" className='mobile-item' onClick={toggleDrawer(false)}>Membership</Link>
            <Link to="/contact" className='mobile-item' onClick={toggleDrawer(false)}>Contact</Link>
            <Link to="/donation" className='mobile-item' onClick={toggleDrawer(false)}>Donation</Link>
            <Link to={buttonDetails?.loginbuttonurl} className='mobile-item'
                  onClick={toggleDrawer(false)}>{buttonDetails?.loginbuttontext || 'Login'}</Link>
            <Link to={buttonDetails?.signupbuttonurl} className='mobile-item'
                  onClick={toggleDrawer(false)}>{buttonDetails?.signupbuttontext || 'Sign up'}</Link>
        </Box>
        */
    );

    return (
        <div className="App-header">
            <Drawer open={open} onClose={toggleDrawer(false)}>
                {DrawerList}
            </Drawer>
            {!isScrolled && <div className="app-bar">
                <div className="social-link-container container">
                    <a href="https://www.facebook.com/Pythiangames1/?ref=pages_you_manage"
                       className='facebook-icon'>
                        <FaFacebookF/>
                    </a>
                    <a href="https://twitter.com/i/flow/login?redirect_after_login=%2FBijendergoel"
                       className='twitter-icon'>
                        <FaXTwitter/>
                    </a>
                    <a href="https://www.instagram.com/modernpythiangames/?igshid=YmMyMTA2M2Y%3D"
                       className='instagram-icon'>
                        <FaInstagram/>
                    </a>
                    <a href="https://www.linkedin.com/authwall?trk=gf&trkInfo=AQHGg-Faq3M32gAAAYk05M_wD13kUDJOKUxLvDuCLgnuX8Z4uEmfwTtpdryScnM4IwiV5JtfrHGuPJXFFN6UfFaUmFTTsfaB3FfwqcKYrHY8cG8Tve55ONVx7NCoUAVL-BviRCA=&original_referer=https://pythiangames.org/&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fbijender-goel-773b6b20%2F"
                       className='linkedin-icon'>
                        <FaLinkedin/>
                    </a>
                    <a href="https://www.youtube.com/channel/UCr5Hz1CEmW56sdTkgAJYG_A"
                       className='youtube-icon'
                       style={{marginRight: '10px'}}>
                        <FaYoutube/>
                    </a>
                    <div className="select-language">
                        <FaEarthAmericas/>
                        <div id="google_translate_element"></div>
                    </div>
                </div>
            </div>}
            <section className={isScrolled || isMobile ? "scrolled" : ""}>
                <div
                    className={isScrolled || isMobile ? "header-section-scrolled container" : "header-section container"}>
                    <div className={isScrolled ? "" : "header-section-image"}>
                        <img src={logo} style={{
                            margin: isScrolled ? '5px 0' : '0px', height: isScrolled || isMobile ? '45px' : '80px'
                        }}/>
                    </div>
                    {isMobile ? ('') : (
                        <ul className="header-section-tabs">{
                            Object.keys(menuData).map((key1, index) => (
                                <li
                                    key={index}
                                    className={menuData[key1]?.items && menuData[key1]?.items?.length != 0 ? "nav-item dropdown" : "nav-item"}>{
                                    menuData[key1]?.items?.length > 0 ? (
                                        <>
                                            <Link
                                                className={isScrolled ? "nav-link scrolled-item" : "nav-link"}>
                                                {menuData[key1]?.menuTitle}<FaAngleDown/>
                                            </Link>
                                            <ul className="dropdown-menu">{
                                                menuData[key1]?.items.map((item1, index) => (
                                                    item1.items?.length > 0 ? (
                                                        <li key={index} className={"nav-item dropdown"}>
                                                            <Link to={item1?.clickable && item1?.link}
                                                                  target={item1?.inNewTab ? "_blank" : "_self"}
                                                                  className="dropdown-item">{item1?.title}</Link>
                                                            <ul className="submenu-menu">
                                                                {
                                                                    item1?.items?.map((item2, index) => (
                                                                        <li key={index}>
                                                                            <Link to={item2?.clickable && item2?.link}
                                                                                  target={item2?.inNewTab ? "_blank" : "_self"}
                                                                                  className="dropdown-item">{item2?.title}</Link>
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        </li>
                                                    ) : (
                                                        <li key={index} className={"nav-item"}>
                                                            <Link to={item1?.clickable && item1?.link}
                                                                  target={item1?.inNewTab ? "_blank" : "_self"}
                                                                  className="dropdown-item">{item1?.title}</Link>
                                                        </li>
                                                    )
                                                ))
                                            }</ul>
                                        </>
                                    ) : (
                                        <Link to={menuData[key1]?.clickable && menuData[key1]?.link}
                                              target={menuData[key1]?.inNewTab ? "_blank" : "_self"}
                                              className={isScrolled ? "nav-link scrolled-item" : "nav-link"}
                                              style={{textDecoration: 'none'}}>
                                            {menuData[key1]?.menuTitle}
                                        </Link>
                                    )
                                }</li>
                            ))
                        }</ul>
                    )}
                    <div>
                        {isMobile ? (<div style={{gap: '15px', display: 'flex'}}>
                            <LuMenu style={{fontSize: '30px', cursor: 'pointer'}} onClick={toggleDrawer(true)}/>
                            <CgProfile style={{fontSize: '30px', cursor: 'pointer'}}
                                       onClick={() => window.location.href = buttonDetails?.loginbuttonurl}/>
                            {/* <CgProfile style={{fontSize: '30px', cursor: 'pointer'}} onClick={() => window.location.href = '/login'}/> */}
                        </div>) : (
                            <div className="header-section-account-register">
                                <button className={isScrolled || isMobile ? 'scrolled-btn' : ''}
                                        onClick={() => window.location.href = buttonDetails?.signupbuttonurl}>{buttonDetails?.signupbuttontext || 'Sign up'}</button>
                                <div className={isScrolled || isMobile ? "scrolled-line" : "line"}></div>
                                <button className={isScrolled || isMobile ? 'scrolled-btn' : ''}
                                        onClick={() => window.location.href = buttonDetails?.loginbuttonurl}>{buttonDetails?.loginbuttontext || 'Login'}</button>
                            </div>

                            // <div className="header-section-account-register">
                            //     <button className={isScrolled || isMobile ? 'scrolled-btn' : ''}
                            //             onClick={() => window.location.href = '/signup'}>{'Sign up'}</button>
                            //     <div className={isScrolled || isMobile ? "scrolled-line" : "line"}></div>
                            //     <button className={isScrolled || isMobile ? 'scrolled-btn' : ''}
                            //             onClick={() => window.location.href = '/login'}>{'Login'}</button>
                            // </div>
                        )}
                    </div>
                </div>
            </section>
        </div>)
}

export default Header;