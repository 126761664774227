import './authentication.css'
import logo from '../../../assets/images/header-logo.png';
import React, {useState} from "react";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import {useNavigate} from "react-router-dom";

const ChooseRole = () => {

    const [category, setCategory] = useState('District Council');
    const navigate = useNavigate();

    return (
        <div className="auth-main">
            <section className="auth-col">
                <div className="auth-bg-col">
                    <div className="auth-side-logo-sec">
                        <img src={logo} alt="logo"/>
                    </div>
                </div>
            </section>
            <section className="auth-col auth-form-sec">
                <div className="auth-form-col">
                    <div className="auth-logo-sec">
                        <img src={logo} alt="logo" className="auth-logo"/>
                    </div>
                    <header className="auth-h1">Welcome to Pythian Games</header>
                    <form
                        className="auth-form"
                        onSubmit={(e) => {
                            e.preventDefault()
                        }}>
                        <div className="auth-form-field">
                            <label htmlFor="select category">
                                <span>Select category</span><em>*</em></label>
                            <select name="select category" required value={category}
                                    onChange={(e) => setCategory(e.target.value)}>
                                <option value="District Council">District Council</option>
                                <option value="Jury/Refree">Jury/Refree</option>
                                <option value="Volunteer">Volunteer</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                        <div>
                            <button onClick={() => navigate('/signup', {state: {category: category}})} type="submit"
                                    className="action-button mt-2 small-btn">
                                <span></span><i>Next <ArrowRightAltIcon/></i>
                            </button>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    )
}
    export default ChooseRole