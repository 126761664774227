import axiosInstance from "../../assets/utils/api";


const getCountdown = async (eventID, pagename) => {
    try {
        const response = await axiosInstance.get(`/api/Countdown`, {
            headers: {
                "pagename": pagename,
                "access-token": "sp=65442Pre1+sdfcf",
                "eventid": eventID
            }
        })
        return response.data;
    } catch (error) {
        throw error;
    }
};
export default getCountdown