import './authentication.css'
import logo from "../../../assets/images/header-logo.png";
import React, {useEffect} from "react";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {useLocation, useNavigate, useParams} from "react-router-dom";

const Signup = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {category} = location.state || {};

    useEffect(() => {
        if (!category) navigate('/chooserole');
    }, []);

    return (
        <div className="auth-main">
            <section className="auth-col">
                <div className="auth-bg-col">
                    <div className="auth-side-logo-sec">
                        <img src={logo} alt="logo"/>
                    </div>
                </div>
            </section>
            <section className="auth-col auth-form-sec">
                <div className="auth-form-col">
                    <div className="auth-logo-sec">
                        <img src={logo} alt="logo" className="auth-logo"/>
                    </div>
                    <header className="auth-h1">Welcome to Pythian Games</header>
                    <form
                        className="auth-form"
                        onSubmit={(e) => {
                            e.preventDefault()
                        }}>
                        <p className="auth-selected-role">You are opted for "{category}"</p>

                        <div className="form-row">
                            <div className="auth-form-field form-holder">
                                <label htmlFor="fname">
                                    <span>First name</span><em>*</em></label>
                                <input type="text" name="fname" required
                                       placeholder="First name"/>
                            </div>
                            <div className="auth-form-field form-holder">
                                <label htmlFor="lname">
                                    <span>Last name</span></label>
                                <input type="text" name="lname" placeholder="Last name"/>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="auth-form-field form-holder">
                                <label htmlFor="dob">
                                    <span>DOB</span><em>*</em></label>
                                <input type="date" name="dob" required
                                       placeholder="dd/MM/yyyy"/>
                            </div>
                            <div className="auth-form-field form-holder">
                                <label htmlFor="gender">
                                    <span>Gender</span><em>*</em></label>
                                <select name="gender" required defaultValue="">
                                    <option value="" disabled>--Select--</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="auth-form-field form-holder">
                                <label htmlFor="country">
                                    <span>Country</span><em>*</em></label>
                                <select name="country" required>
                                    <option value="india">India</option>
                                    <option value="america">America</option>
                                    <option value="china">China</option>
                                </select>
                            </div>
                            <div className="auth-form-field form-holder">
                                <label htmlFor="state">
                                    <span>State</span><em>*</em></label>
                                <select name="state" required defaultValue="">
                                    <option value="" disabled>--Select--</option>
                                    <option value="delhi">Delhi</option>
                                    <option value="bihar">Behar</option>
                                    <option value="gujrat">Gujrat</option>
                                    <option value="chandigarh">Chandigarh</option>
                                </select>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="auth-form-field form-holder">
                                <label htmlFor="pincode">
                                    <span>Pincode</span><em>*</em></label>
                                <input type="number" maxLength={6} minLength={6} inputMode="number" name="pincode"
                                       required
                                       placeholder="Pincode"/>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="auth-form-field form-holder">
                                <label htmlFor="email">
                                    <span>Email</span></label>
                                <input type="email" inputMode="email" name="email"
                                       placeholder="example@example.com"/>
                            </div>
                            <div className="auth-form-field form-holder">
                                <label htmlFor="phone">
                                    <span>Phone no.</span><em>*</em></label>
                                <div style={{display: "flex"}}>
                                    <input type="text" style={{width: '50px'}} value="+91" disabled/>
                                    <input type="tel" maxLength={10} minLength={10} inputMode="tel" name="phone"
                                           required/>
                                </div>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="auth-form-field form-holder">
                                <label htmlFor="password">
                                    <span>Password</span><em>*</em></label>
                                <input type="password" minLength={8} name="password" required
                                       placeholder="Enter password"/>
                            </div>
                            <div className="auth-form-field form-holder">
                                <label htmlFor="confirm password">
                                    <span>Confirm password</span><em>*</em></label>
                                <input type="password" minLength={8} name="confirm password" required
                                       placeholder="Confirm password"/>
                            </div>
                        </div>

                        <div className="auth-btn-container">
                            <button onClick={() => navigate('/signup')} type="button"
                                    className="action-button-outlined mt-2 small-btn">
                                <span></span><i><KeyboardBackspaceIcon/> Back</i>
                            </button>
                            <button type="submit" className="action-button mt-2 small-btn">
                                <span></span><i>Sign in</i>
                            </button>
                        </div>

                    </form>
                </div>
            </section>
        </div>
    )
}
export default Signup