import './authentication.css'
import logo from '../../../assets/images/header-logo.png';
import React from "react";

const Login = () => {
    return (
        <div className="auth-main">
            <section className="auth-col">
                <div className="auth-bg-col">
                    <div className="auth-side-logo-sec">
                        <img src={logo} alt="logo"/>
                    </div>
                </div>
            </section>
            <section className="auth-col auth-form-sec">
                <div className="auth-form-col">
                    <div className="auth-logo-sec">
                        <img src={logo} alt="logo" className="auth-logo"/>
                    </div>
                    <header className="auth-h1">Login Here</header>
                    <form
                        className="auth-form"
                        onSubmit={(e) => {
                            e.preventDefault()
                        }}>
                        <div className="auth-form-field">
                            <label htmlFor="username">
                                <span>Username</span><em>*</em></label>
                            <input type="text" name="username" required
                                   placeholder="Enter Email / Mobile Number / Username"/>
                        </div>
                        <div className="auth-form-field">
                            <label htmlFor="password">
                                <span>Password</span><em>*</em></label>
                            <input type="text" name="password" required placeholder="Enter Password"/>
                        </div>
                        <div className="d-flex justify-content-between">
                            <label htmlFor="rememberme"> <input type="checkbox" name="rememberme"/> Remember this
                                device</label>
                            <a className="auth-forgot-btn">Forgot Password?</a>
                        </div>
                        <div>
                            <button type="submit" className="action-button mt-2 small-btn">
                                <span></span><i>Sign in</i>
                            </button>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    )
}
export default Login