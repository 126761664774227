import axiosInstance from "../../assets/utils/api";


const getMenu = async () => {
    try {
        const response = await axiosInstance.get(`/api/Menu`, {
            headers: {
                "access-token": "MaMB@1ne$322anayaenust",
            }
        })
        return response.data;
    } catch (error) {
        throw error;
    }
};
export default getMenu;